import * as React from 'react';
import { useRoutes } from 'react-router-dom';
import Login from '../pages/login';
import { NotFound } from '../components';

export default function AppRoutes() {
    return useRoutes(
        [
            {
                path: '/',
                element: Login(),
            },
            {
              path: '*',
              element: NotFound(),
            },
            
        ]
    )
}