import axios from 'axios';

axios.interceptors.request.use(
    (config) => {
        config.baseURL = "https://id.ktcmap.com";
        config.headers ={ 
          'Content-Type': 'application/json',
          'Host': 'id.ktcmap.com',
          'Referer' : 'https://id.ktcmap.com',
          'Accept' : '*/*',
          'Accept-Encoding' : 'gzip, deflate, br',
          'Connection' : 'keep-alive'
      } 
        return config;
    },
    (error) => {
        console.log(`ERROR : ${JSON.stringify(error)}`);
        Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
      console.log("Response received:", response)
      return response
    },
    (error) => {
      return Promise.reject(error)
    }
  )