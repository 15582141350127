import React, {useState} from 'react'
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Checkbox,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import {ViewIcon, ViewOffIcon} from '@chakra-ui/icons';
import axios from 'axios';

export default function Login() {
    const [showPassword, setShowPassword] = useState(false);
    const handleShow = () => setShowPassword(!showPassword);
    
    const [phoneInput, setPhoneInput] = useState<string>('');
    const [passwordInput, setPasswordInput] = useState<string>('');

    interface Payload {
      phoneNo: string,
      password: string
    }

    const login = (payload : Payload) => {
      axios
        .post("/api/auth/login", payload)
        .then((response) => {
          // handle success
          console.log(response.data)
        })
        .catch((error) => {
          // handle error
          console.error(error)
        })
    }


  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Sign in to your account</Heading>
          <Text fontSize={'lg'} color={'gray.600'}>
            to enjoy all of our cool <Text color={'blue.400'}>features</Text> ✌️
          </Text>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            <FormControl id="phone">
              <FormLabel>Phone</FormLabel>
              <Input onChange={(event) => setPhoneInput(event.target.value)} value={phoneInput}  type="text" />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <InputGroup size="md">
                <Input
                  onChange={(event) => setPasswordInput(event.target.value)} value={passwordInput} 
                  pr="4.5rem"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                />
                <InputRightElement width="4.5rem">
                    <IconButton
                    aria-label={showPassword ? "Mask password" : "Reveal password"}
                    icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                    onClick={handleShow}
                    />
                </InputRightElement>
                </InputGroup>
            </FormControl>
            <Stack spacing={10}>
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'space-between'}>
                <Checkbox>Remember me</Checkbox>
                <Text color={'blue.400'}>Forgot password?</Text>
              </Stack>
              <Button
              onClick={() => {
                login({phoneNo : phoneInput,password : passwordInput});
              }}
              bg={'blue.400'}
              color={'white'}
              _hover={{
                bg: 'blue.500',
              }}>
                Sign in
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  )
}